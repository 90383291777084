import styles from './styles.module.scss'

export function SkeletonText() {
    return (
        <div className={styles.skeletonText}>
            
        </div>
    )
}

export function SkeletonImage() {
    return (
        <div className={styles.skeletonImage}>

        </div>
    )
}

export function SkeletonTitle() {
    return (
        <div className={styles.skeletonTitle}>
            
        </div>
    )
}


export function SkeletonSubtitle() {
    return (
        <div className={styles.skeletonSubtitle}>
            
        </div>
    )
}

